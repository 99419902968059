.receiptpage{
    background: url("/public/assets/img/receipt.jpg") repeat !important;
    /* opacity:0.8; */
    min-height: 60vh;
     max-width: 500px;
     border: 1px solid #aaa;
     font-family: 'Courier New',Courier,monospace;
     padding: 30px 10px;
     font-size: 15px;
     text-align: center;
}

.receiptpage .left {
    text-align: left;
}

.receiptpage .right {
    text-align: right;
}

.receiptpage .center {
    text-align: center;
}

.receiptpage .bold {
    font-weight: bold;
}

.segment{
    margin: 3px;
}

.hr {
    display: block;
    unicode-bidi: isolate;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: auto;
    margin-inline-end: auto;
    overflow: hidden;
    border-style: inset;
    border-width: 1px;
}